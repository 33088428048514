<template>
    <modal ref="modalDetallePagoTransferencia" titulo="Detalle del pago" no-aceptar no-cancelar>
        <div class="row mx-0">
            <div class="col-auto px-2" />
            <div class="col px-2">
                <div class="row mx-0 mb-4">
                    <div class="col d-flex justify-center text-center">
                        <span class="f-600 text-general">Fecha y hora <br /> del pago </span>
                        <p class="d-middle ml-2 text-general">{{ pago.fecha }}</p>
                    </div>
                </div>
                <div class="row mx-0 mb-4">
                    <div class="col d-flex justify-center text-center">
                        <inner-image-zoom :src="pago.imagen" :zoom-src="`${pago.imagen}`" class="obj-cover border br-4" style="width:260px;max-height:100%;" />
                    </div>
                </div>
                <div class="row mx-0 mb-4">
                    <div class="col d-flex justify-center text-center">
                        {{ pago.observacion }}
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import pagos from '~/services/pagos/pagos'
export default {

    data(){
        return {
            pago: {imagen:''},
        }
    },
    methods: {
        async toggle(pago){
            await this.fetchDetalle(pago)
            this.$refs.modalDetallePagoTransferencia.toggle();
        },
        async fetchDetalle({ id }){
            try {
                const params = { id }
                const { data } = await pagos.detallePago(params)
                this.pago = data
            } catch (error){
                this.error_catch(error)
            }
        },
    },
}
</script>
<style lang="css" scoped>
.aprobado{
    background-color: #27D07B;
    color: #ffffff;
}
.pendiente{
    background-color: #F5F5F5;
    color: #707070;
}
.cancelado{
    background-color: #FF3B63;
    color: #ffffff;
}
</style>
